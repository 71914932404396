import {Component, OnDestroy, OnInit} from '@angular/core';
import {Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {MenuState} from 'src/app/states/menu.state';
import {Observable, Subject} from 'rxjs';
import {MenuModelResponse} from 'src/app/api/responses/menu-response';
import MenuGroup from 'src/app/elements/menu/menu-group';
import version from 'src/environments/version';
import {takeUntil} from 'rxjs/operators';
import {FetchMenu} from 'src/app/states/menu.actions';
import {union} from 'lodash';

@Component({
    selector: 'app-licenses-menu',
    templateUrl: './licenses-menu.component.html',
    styleUrls: ['./licenses-menu.component.scss']
})
export class LicensesMenuComponent implements OnInit, OnDestroy {

    public readonly version = version;

    public mainMenuItems: MenuItem[] = [];
    public mainMenuGroups: MenuGroup[] = [];

    @Select(MenuState.menu)
    public menuListener: Observable<MenuModelResponse | null>;
    private menu: MenuModelResponse | null = null;

    @Select(MenuState.activeAccountId)
    public activeAccountIdListener: Observable<number | null>;
    private activeAccountId: number | null = null;

    public isLoading = false;

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    private gc = new Subject();

    constructor(
        private store: Store,
        private actions: Actions
    ) {
    }

    public ngOnInit(): void {
        this.initEvents();
        this.fetchData();
    }

    private initEvents() {
        this.activeAccountIdListener
            .pipe(takeUntil(this.gc))
            .subscribe(activeAccountId => {
                this.activeAccountId = activeAccountId;
                this.buildMenu();
            });

        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => {
                this.menu = menu;
                this.buildMenu();
            });

        this.actions
            .pipe(ofActionCompleted(FetchMenu))
            .pipe(takeUntil(this.gc))
            .subscribe(() => this.isLoading = false);
    }

    private fetchData() {
        this.cancellationToken.next();
        this.isLoading = true;

        this.store.dispatch(new FetchMenu(this.cancellationToken));
    }

    /**
     * Buduje menu na podstawie dostępności pozycji dla wybranych pozycji,
     * np. "Trials" nie zostanie wyświetlone jeśli ktoś nie ma licencji trial.
     */
    private buildMenu() {
        const groups: MenuGroup[] = [];
        let items: MenuItem[] = [];

        if (!this.menu) {
            this.mainMenuGroups = groups;
            this.mainMenuItems = items;
            return;
        }

        // Pozycje w menu dla licencji przypisanych do organizacji
        for (const account of this.menu.accounts) {
            const accountMenuItems = [];
            const accountKey = `ACCOUNT_${account.accountId}`;

            if (account.subscription) {
                accountMenuItems.push({
                    key: `SUBSCRIPTION_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'active-state',
                    name: 'Subscription',
                    routerLink: `/accounts/${account.accountId}/subscription`,
                });
            }

            if (account.subscriptionUsers) {
                accountMenuItems.push({
                    key: `SUBSCRIPTION_USERS_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'user-account',
                    name: 'Users',
                    routerLink: `/accounts/${account.accountId}/users`,
                });
            }

            if (account.perpetualLicenses) {
                accountMenuItems.push({
                    key: `PERPETUAL_LICENSES_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'package',
                    name: 'Perpetual Licenses',
                    routerLink: `/accounts/${account.accountId}/perpetual-licenses`,
                })
            }

            if (account.orders) {
                accountMenuItems.push({
                    key: `ORDERS_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'order-history',
                    name: 'Orders & Invoices',
                    routerLink: `/accounts/${account.accountId}/orders`,
                });
            }

            if (account.quotes) {
                accountMenuItems.push({
                    key: `QUOTES_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'shopping-cart',
                    name: 'Quotes',
                    routerLink: `/accounts/${account.accountId}/quotes`,
                });
            }

            if (account.billing) {
                accountMenuItems.push({
                    key: `BILLING_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'card-payment',
                    name: 'Billing & Payment',
                    routerLink: `/accounts/${account.accountId}/billing`,
                });
            }

            if (account.administration) {
                accountMenuItems.push({
                    key: `ROLES_${accountKey}`,
                    groupKey: accountKey,

                    icon: 'administrative-tools',
                    name: 'Administration',
                    routerLink: `/accounts/${account.accountId}/roles`,
                });
            }

            if (this.activeAccountId === account.accountId) {
                items = union(items, accountMenuItems);
            }

            groups.push({
                key: accountKey,
                name: account.accountName,
                badge: {
                    label: account.accountId,
                    color: '#c2c2c2',
                },
                routerLink: `/accounts/${account.accountId}`,
            });
        }

        // Pozycje w menu dla licencji użytkownika
        const personalMenuItems = [];

        if (this.menu.personal.trials) {
            personalMenuItems.push({
                key: 'TRIALS',
                groupKey: 'PERSONAL_LICENSES',

                icon: 'test-tube',
                name: 'Trials',
                routerLink: '/trials',
            });
        }

        if (this.menu.personal.educationalLicenses) {
            personalMenuItems.push({
                key: 'EDUCATIONAL_LICENSES',
                groupKey: 'PERSONAL_LICENSES',

                icon: 'graduation-cap',
                name: 'Educational Licenses',
                routerLink: '/educational-licenses',
            });
        }

        if (personalMenuItems.length > 0) {
            if (this.activeAccountId === null) {
                items = union(items, personalMenuItems)
            }

            groups.push({
                key: 'PERSONAL_LICENSES',
                name: this.menu.personal.accountName,
                routerLink: personalMenuItems[0].routerLink,
            });
        }

        this.mainMenuGroups = groups;
        this.mainMenuItems = items;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
