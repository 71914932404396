import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AuthState} from '../../states/auth.state';
import {Observable, Subject} from 'rxjs';
import ProfileResponse from '../../api/responses/profile-response';
import {FetchProfile} from '../../states/auth.actions';
import {MenuModelResponse} from '../../api/responses/menu-response';
import {takeUntil} from 'rxjs/operators';
import {MenuState} from '../../states/menu.state';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

    @Select(AuthState.profile)
    public profile$: Observable<ProfileResponse | null>;
    @Select(MenuState.menu)
    public menuListener: Observable<MenuModelResponse | null>;
    public menu: MenuModelResponse | null = null;

    private componentDestroyed = new Subject();
    private gc = new Subject();

    constructor(private store: Store) {
    }

    private getMenu() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => {
                this.menu = menu;
            });
    }

    public ngOnInit(): void {
        this.store.dispatch(new FetchProfile(this.componentDestroyed));
        this.getMenu();
    }

    public ngOnDestroy(): void {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
