import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthState } from '../../states/auth.state';
import { Subject } from 'rxjs';
import { FetchProfile } from '../../states/auth.actions';
import { takeUntil } from 'rxjs/operators';
import { MenuState } from '../../states/menu.state';
export class NavbarComponent {
    constructor(store) {
        this.store = store;
        this.menu = null;
        this.componentDestroyed = new Subject();
        this.gc = new Subject();
    }
    getMenu() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => {
            this.menu = menu;
        });
    }
    ngOnInit() {
        this.store.dispatch(new FetchProfile(this.componentDestroyed));
        this.getMenu();
    }
    ngOnDestroy() {
        this.componentDestroyed.next();
        this.componentDestroyed.complete();
    }
}
tslib_1.__decorate([
    Select(AuthState.profile)
], NavbarComponent.prototype, "profile$", void 0);
tslib_1.__decorate([
    Select(MenuState.menu)
], NavbarComponent.prototype, "menuListener", void 0);
