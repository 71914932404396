import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {MenuState} from '../../states/menu.state';
import {Observable, Subject} from 'rxjs';
import {MenuModelResponse} from '../../api/responses/menu-response';
import {Title} from '@angular/platform-browser';
import {takeUntil} from 'rxjs/operators';
import {FetchMenu, PushMenuActiveAccountId} from '../../states/menu.actions';
import IntercomHelper from '../../helpers/intercom-helper';
import {DownloadLastVersion, DownloadModelResponse} from '../../api/responses/download-response';
import {FetchDownload} from '../../states/download.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {DownloadRepositoryService} from '../../api/repositories/download-repository.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-download-page',
    templateUrl: './download-page.component.html',
    styleUrls: ['./download-page.component.scss']
})

export class DownloadPageComponent implements OnInit, OnDestroy {

    public freeTrialUrl = `${environment.websiteUrl}free-trial`;
    constructor(
        private store: Store,
        private actions: Actions,
        private title: Title,
        private downloadRepository: DownloadRepositoryService,
    ) {
    }

    public loading: boolean = true;

    @Select(MenuState.menu)
    public menuListener: Observable<MenuModelResponse | null>;
    public menu: MenuModelResponse | null = null;

    public download: DownloadModelResponse[];
    public downloadLastVersion: DownloadLastVersion = null;

    public isLoading = true;

    private gc = new Subject();

    // Token służący do anulowania pobierania danych.
    private cancellationToken = new Subject();

    public get shouldShowPersonalLicenses(): boolean {
        if (!this.menu) {
            return false;
        }

        return this.menu.personal.trials || this.menu.personal.educationalLicenses;
    }

    public get hasAnyLicenses(): boolean {
        if (!this.menu) {
            return false;
        }

        return this.shouldShowPersonalLicenses
            || this.menu.accounts.length > 0;
    }

    public openChat(): void {
        IntercomHelper.show();
    }

    public ngOnInit(): void {
        this.isLoading = true;

        this.title.setTitle('Your download - Dataedo Account');
        this.initEvents();
        this.fetchData();
        this.downloadRepository.list().pipe(takeUntil(this.gc))
            .subscribe(data => {
                this.downloadLastVersion = data.data.lastVersion;
                this.download = data.data.versions;
                this.isLoading = false
            });
    }

    private initEvents() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => this.menu = menu);

        this.actions
            .pipe(ofActionCompleted(FetchDownload))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchDownload(event));

        this.actions
            .pipe(ofActionCompleted(FetchMenu))
            .pipe(takeUntil(this.gc))
            .subscribe();
    }

    private fetchData() {
        this.isLoading = true;

        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchDownload(this.cancellationToken));
        this.store.dispatch(new FetchMenu(this.gc));
    }

    private onCompletedFetchDownload(event: ActionCompletion<FetchDownload, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        this.isLoading = false;
    }

    public ngOnDestroy(): void {
        this.gc.next();
        this.gc.complete();
    }
}
