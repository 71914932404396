import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import DownloadResponse from '../responses/download-response';

@Injectable({
    providedIn: 'root'
})
export class DownloadRepositoryService {
    constructor(private http: HttpClient) {
    }

    public list(): Observable<DownloadResponse> {
        return this.http.get<DownloadResponse>(`${environment.apiUrl}v1/downloads`);
    }
}
