import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class DownloadRepositoryService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get(`${environment.apiUrl}v1/downloads`);
    }
}
DownloadRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadRepositoryService_Factory() { return new DownloadRepositoryService(i0.ɵɵinject(i1.HttpClient)); }, token: DownloadRepositoryService, providedIn: "root" });
