<app-stack-layout>
    <!--<app-spinner class="fullsize" *ngIf="isLoading"></app-spinner>-->
    <app-empty *ngIf="!isLoading && !hasAnyLicenses" class="fullsize">
        <app-stack-layout class="text-center">
            <app-icon class="empty-icon" icon="sad" size="120px"></app-icon>
            <h2 class="mt-md">No licenses found</h2>
            <p class="mt-sm">You cannot download older version of Dataedo.</p>

            <div class="mt-md">
                <app-button-link href="https://dataedo.com/download" icon="download" [openInNewTab]="true" class="mr-md">Latest version</app-button-link>
                <app-button (click)="openChat()" class="mr-sm">
                    Contact support
                </app-button>
                <app-button-link [href]="freeTrialUrl" [openInNewTab]="true">
                    Get free trial
                    <app-icon class="ml-xs" icon="external-link" size="16px"></app-icon>
                </app-button-link>
            </div>
        </app-stack-layout>
    </app-empty>

    <div class="mt-lg ml-lg" *ngIf="hasAnyLicenses">
        <h1>Your Downloads</h1>

        <div class="mt-lg">
            <h3 class="mt-xs">Recent version:</h3>
            <ng-container *ngIf="download; else loading">
                <table style="margin-left:20px">
                    <thead>
                    <tr>
                        <th>Product</th>
                        <th>Version</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Download x32</th>
                        <th>Download x64</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let version of download">
                        <tr *ngIf="version.version.major === downloadLastVersion.major">
                            <td>{{version.name}}</td>
                            <td>{{version.version.major}}.{{version.version.minor}}.{{version.version.build}}</td>
                            <td>{{version.type}}</td>
                            <td>{{version.size}}</td>
                            <td><a *ngIf="version.link32" href="{{version.link32}}" download="true">Download</a></td>
                            <td><a *ngIf="version.link64" href="{{version.link64}}" download="true">Download</a></td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </ng-container>
        </div>


        <h3 class="mt-lg">Older versions:</h3>
        <ng-container *ngIf="download; else loading">
            <table style="margin-left:20px">
                <thead>
                <tr>
                    <th>Product</th>
                    <th>Version</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Download x32</th>
                    <th>Download x64</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let version of download">
                    <tr *ngIf="version.version.major !== downloadLastVersion.major">
                        <td>{{version.name}}</td>
                        <td>{{version.version.major}}.{{version.version.minor}}.{{version.version.build}}</td>
                        <td>{{version.type}}</td>
                        <td>{{version.size}}</td>
                        <td><a *ngIf="version.link32" href="{{version.link32}}" download="true">Download</a></td>
                        <td><a href="{{version.link64}}" download="true">Download</a></td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </ng-container>

        <ng-template #loading>
            <app-spinner></app-spinner>
        </ng-template>

        <div class="mt-md">
            <app-button (click)="openChat()" class="mr-sm">
                Contact support
            </app-button>
        </div>
    </div>

    <div class="pt-md"><!-- Empty space at the bottom --></div>
</app-stack-layout>
