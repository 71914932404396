import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GuestPageComponent} from './pages/guest-page/guest-page.component';
import {LoginEmailPageComponent} from './pages/login-email-page/login-email-page.component';
import {LoginConfirmPageComponent} from './pages/login-confirm-page/login-confirm-page.component';
import {NotFoundPageComponent} from './pages/not-found-page/not-found-page.component';
import {LogoutPageComponent} from './pages/logout-page/logout-page.component';
import {SpinnerComponent} from './elements/spinner/spinner.component';
import {LabelComponent} from './elements/label/label.component';
import {InputComponent} from './elements/input/input.component';
import {ButtonComponent} from './elements/button/button.component';
import {StackLayoutComponent} from './elements/stack-layout/stack-layout.component';
import {TooltipComponent} from './elements/tooltip/tooltip.component';
import {LoginTroubleshootingComponent} from './pages/login-troubleshooting/login-troubleshooting.component';
import {ErrorComponent} from './elements/error/error.component';
import {LoginRegisterPageComponent} from './pages/login-register-page/login-register-page.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from './states/auth.state';
import {environment} from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AlertsState} from './states/alerts.state';
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {AlertsComponent} from './components/alerts/alerts.component';
import {AppPageComponent} from './pages/app-page/app-page.component';
import {TrialsPageComponent} from './pages/trials-page/trials-page.component';
import {TrialsState} from './states/trials.state';
import {UnauthorizedInterceptor} from './api/interceptors/unauthorized';
import {ForbiddenInterceptor} from './api/interceptors/forbidden';
import {InternalServerErrorInterceptor} from './api/interceptors/internal-server-error';
import {IconComponent} from './elements/icon/icon.component';
import {TabbedLinksDirective} from './helpers/directives/tabbed-links.directive';
import {BadgeComponent} from './elements/badge/badge.component';
import {TimestampComponent} from './elements/timestamp/timestamp.component';
import {EmptyComponent} from './elements/empty/empty.component';
import {EducationalLicensesPageComponent} from './pages/educational-licenses-page/educational-licenses-page.component';
import {EducationalLicensesState} from './states/educational-licenses.state';
import {ClipboardModule} from 'ngx-clipboard';
import {LicenseKeyComponent} from './components/license-key/license-key.component';
import {PerpetualLicensesPageComponent} from './pages/perpetual-licenses-page/perpetual-licenses-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SubscriptionsState} from './states/subscriptions.state';
import {PerpetualLicensesState} from './states/perpetual-licenses.state';
import {PopoverComponent} from './elements/popover/popover.component';
import {MenuComponent} from './elements/menu/menu.component';
import {OrdersPageComponent} from './pages/orders-page/orders-page.component';
import {OrdersState} from './states/orders.state';
import {QuotesPageComponent} from './pages/quotes-page/quotes-page.component';
import {QuotesState} from './states/quotes.state';
import {OrderPageComponent} from './pages/order-page/order-page.component';
import {CopyToClipboardComponent} from './components/copy-to-clipboard/copy-to-clipboard.component';
import {ModalComponent} from './elements/modal/modal.component';
import {TextAlignDirective} from './directives/text-align.directive';
import {NotFoundInterceptor} from './api/interceptors/not-found';
import {QuotePageComponent} from './pages/quote-page/quote-page.component';
import {DocumentComponent} from './components/document/document.component';
import {ButtonLinkComponent} from './elements/button-link/button-link.component';
import {MoneyFormatPipe} from './pipes/money-format.pipe';
import {MenuState} from './states/menu.state';
import {CheckboxComponent} from './elements/checkbox/checkbox.component';
import {LicensesMenuComponent} from './components/licenses-menu/licenses-menu.component';
import {AccountsPageComponent} from './pages/accounts-page/accounts-page.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {AccountMenuPageComponent} from './pages/account-menu-page/account-menu-page.component';
import {AccountRolesPageComponent} from './pages/account-roles-page/account-roles-page.component';
import {AccountRolesState} from 'src/app/states/account-roles.state';
import {SubscriptionUsersPageComponent} from './pages/subscription-users-page/subscription-users-page.component';
import {SubscriptionPageComponent} from 'src/app/pages/subscription-page/subscription-page.component';
import {AccountsState} from 'src/app/states/accounts.state';
import {PageBottomSpaceComponent} from './elements/page-bottom-space/page-bottom-space.component';
import {SelectComponent} from './elements/select/select.component';
import {AccountBillingPageComponent} from './pages/account-billing-page/account-billing-page.component';
import {CountriesState} from 'src/app/states/countries.state';
import {AccountBillingEditPageComponent} from 'src/app/pages/account-billing-edit-page/account-billing-edit-page.component';
import {AccountRolesEditPageComponent} from 'src/app/pages/account-roles-edit-page/account-roles-edit-page.component';
import {SingleSignOnPageComponent} from './pages/single-sign-on-page/single-sign-on-page.component';
import {AccountHomePageComponent} from './pages/account-home-page/account-home-page.component';
import {SessionsPageComponent} from './pages/sessions-page/sessions-page.component';
import {SessionsState} from './states/sessions.state';
import {KeysPipe} from './pipes/keys.pipe';
import {SubscriptionUsersEditPageComponent} from './pages/subscription-users-edit-page/subscription-users-edit-page.component';
import {ConvertLegacyLicensePageComponent} from './pages/convert-legacy-license-page/convert-legacy-license-page.component';
import {TextareaComponent} from './elements/textarea/textarea.component';
import {PackageModulesComponent} from './components/package-modules/package-modules.component';
import { UrlencodePipe } from './pipes/urlencode.pipe';
import { LoggedInPageComponent } from './pages/logged-in-page/logged-in-page.component';
import {DownloadPageComponent} from './pages/download-page/download-page.component';

@NgModule({
    declarations: [
        AppComponent,
        GuestPageComponent,
        LoginEmailPageComponent,
        LoginConfirmPageComponent,
        NotFoundPageComponent,
        LogoutPageComponent,
        SpinnerComponent,
        LabelComponent,
        InputComponent,
        ButtonComponent,
        StackLayoutComponent,
        TooltipComponent,
        LoginTroubleshootingComponent,
        ErrorComponent,
        LoginRegisterPageComponent,
        AlertsComponent,
        AppPageComponent,
        TrialsPageComponent,
        IconComponent,
        TabbedLinksDirective,
        BadgeComponent,
        TimestampComponent,
        EmptyComponent,
        EducationalLicensesPageComponent,
        LicenseKeyComponent,
        PerpetualLicensesPageComponent,
        PopoverComponent,
        MenuComponent,
        OrdersPageComponent,
        QuotesPageComponent,
        OrderPageComponent,
        CopyToClipboardComponent,
        ModalComponent,
        TextAlignDirective,
        QuotePageComponent,
        DocumentComponent,
        ButtonLinkComponent,
        MoneyFormatPipe,
        CheckboxComponent,
        LicensesMenuComponent,
        AccountsPageComponent,
        NavbarComponent,
        AccountMenuPageComponent,
        AccountRolesPageComponent,
        AccountRolesEditPageComponent,
        SubscriptionUsersPageComponent,
        SubscriptionPageComponent,
        PageBottomSpaceComponent,
        SelectComponent,
        AccountBillingPageComponent,
        AccountBillingEditPageComponent,
        SingleSignOnPageComponent,
        AccountHomePageComponent,
        SessionsPageComponent,
        KeysPipe,
        SubscriptionUsersEditPageComponent,
        ConvertLegacyLicensePageComponent,
        TextareaComponent,
        PackageModulesComponent,
        UrlencodePipe,
        LoggedInPageComponent,
        DownloadPageComponent
    ],
    imports: [
        FormsModule,
        BrowserModule,
        ClipboardModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxsModule.forRoot([
            AccountRolesState,
            AccountsState,
            AlertsState,
            AuthState,
            CountriesState,
            EducationalLicensesState,
            MenuState,
            OrdersState,
            PerpetualLicensesState,
            QuotesState,
            SessionsState,
            SubscriptionsState,
            TrialsState,
        ], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['Auth', 'Menu'],
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ForbiddenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotFoundInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InternalServerErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    // TODO: W przypadku bledow JS robic screen i pytac o wyslanie bledu (https://html2canvas.hertzen.com/)
}
