<div class="navbar-left">
    <ng-content select="[data-menu-toggle-btn]"></ng-content>

    <a routerLink="/" class="navbar-brand">
        <img alt="Dataedo Website" src="assets/dataedo.svg" height="26">
    </a>
</div>
<div class="navbar-right">

    <div>
        <app-button-link *ngIf="!menu?.isCustomerActive" href="https://dataedo.com/download" icon="download" size="small" [openInNewTab]="true" class="mr-md">Download</app-button-link>

        <ng-container *ngIf="menu?.isCustomerActive">
            <a class="profile mr-md border text-bold text-small" #downloadMenuItem tabindex="0" routerLink="." queryParamsHandling="preserve" style="padding:3px 10px;"> Download
                <app-icon icon="expand-arrow" size="10px"></app-icon>
            </a>

            <app-popover placement="bottom-end" [reference]="downloadMenuItem">
                <div class="text-center px-md py-lg">
                    <div>
                        <app-button-link href="https://dataedo.com/download" icon="download" [openInNewTab]="true" class="mr-md">Latest version</app-button-link>
                    </div>

                    <div style="margin-top:12px;">
                        You can download Dataedo  <a routerLink="/downloads">older versions</a>
                    </div>

                </div>
            </app-popover>
        </ng-container>
    </div>

    <ng-container *ngIf="profile$|async as profile; else menuSpinnerItem">
        <a class="profile" #profileMenuItem tabindex="0">
            <app-icon icon="user"></app-icon>
            <app-icon icon="expand-arrow" size="10px"></app-icon>
        </a>

        <app-popover placement="bottom-end" [reference]="profileMenuItem">
            <div class="text-center px-md py-lg">
                <div>
                    <app-icon icon="user" size="20px"></app-icon>
                </div>

                <div class="pt-md">
                    <div *ngIf="profile.firstName || profile.lastName"
                        class="text-no-wrap text-overflow">
                        {{ profile.firstName }} {{ profile.lastName }}
                    </div>
                    <small class="text-no-wrap text-overflow">{{ profile.email }}</small>
                    <div class="mt-xs">
                        <a routerLink="/sessions" class="text-small"> Manage sessions </a>
                    </div>
                </div>

                <div class="pt-md">
                    <app-button size="small" routerLink="/logout">
                        <app-icon icon="padlock" class="f-no-shrink mr-sm"></app-icon>
                        Logout
                    </app-button>
                </div>
            </div>
        </app-popover>
    </ng-container>
    <ng-template #menuSpinnerItem>
        <app-spinner></app-spinner>
    </ng-template>
</div>
