import {Action, Actions, Selector, State, StateContext} from '@ngxs/store';
import {takeUntil, tap} from 'rxjs/operators';
import {Logout} from './auth.actions';
import {AccountSubscriptionModelResponse} from 'src/app/api/responses/account-subscription.response';
import {AccountsRepositoryService} from 'src/app/api/repositories/accounts-repository.service';
import {
    DownloadOfflineLicense,
    FetchAccountBilling,
    FetchAccountSubscription,
    FetchAccountSummary,
    UpdateBillingDetails,
} from 'src/app/states/accounts.actions';
import {AccountBillingModelResponse} from 'src/app/api/responses/account-billing-response';
import AccountSummaryResponse, {AccountSummaryModelResponse} from '../api/responses/account-summary.response';
import ResponseHandlingHelper from '../helpers/response-handling-helper';

interface AccountsStateModel {
    summary?: AccountSummaryModelResponse;
    subscription?: AccountSubscriptionModelResponse;
    billing?: AccountBillingModelResponse;
}

@State<AccountsStateModel>({
    name: 'Accounts',
    defaults: {
        summary: null,
        subscription: null,
        billing: null,
    }
})
export class AccountsState {
    constructor(
        private actions: Actions,
        private accountsRepositoryService: AccountsRepositoryService
    ) {
    }

    @Selector()
    public static summary(state: AccountsStateModel): AccountSummaryModelResponse {
        return state.summary;
    }

    @Selector()
    public static subscription(state: AccountsStateModel): AccountSubscriptionModelResponse {
        return state.subscription;
    }

    @Selector()
    public static billing(state: AccountsStateModel): AccountBillingModelResponse {
        return state.billing;
    }

    @Action(FetchAccountSummary)
    public fetchAccountSummary(ctx: StateContext<AccountsStateModel>, action: FetchAccountSummary) {
        let request = this.accountsRepositoryService.summaryByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                summary: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(FetchAccountSubscription)
    public fetchAccountSubscription(ctx: StateContext<AccountsStateModel>, action: FetchAccountSubscription) {
        let request = this.accountsRepositoryService.subscriptionByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                subscription: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(DownloadOfflineLicense)
    public downloadOfflineLicense(ctx: StateContext<AccountsStateModel>, action: DownloadOfflineLicense) {
        let request = this.accountsRepositoryService.offlineLicense(action.accountId)
            .pipe(tap(response => {
                ResponseHandlingHelper.downloadFile(response, 'Dataedo_' + action.accountId + '_license.dkey');
            }));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(FetchAccountBilling)
    public fetchAccountBilling(ctx: StateContext<AccountsStateModel>, action: FetchAccountBilling) {
        let request = this.accountsRepositoryService.billingByAccountId(action.accountId)
            .pipe(tap(response => ctx.patchState({
                billing: response.data
            })));

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(UpdateBillingDetails)
    public updateBillingDetails(ctx: StateContext<AccountsStateModel>, action: UpdateBillingDetails) {
        let request = this.accountsRepositoryService.updateBillingByAccountId(action.accountId, action.formData);

        if (action.cancellationToken) {
            request = request.pipe(takeUntil(action.cancellationToken));
        }

        return request;
    }

    @Action(Logout)
    public logout(ctx: StateContext<AccountsStateModel>, action: Logout) {
        ctx.patchState({
            subscription: null,
        });
    }
}
