/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../elements/empty/empty.component.ngfactory";
import * as i3 from "../../elements/empty/empty.component";
import * as i4 from "../../elements/stack-layout/stack-layout.component.ngfactory";
import * as i5 from "../../elements/stack-layout/stack-layout.component";
import * as i6 from "../../elements/icon/icon.component.ngfactory";
import * as i7 from "../../elements/icon/icon.component";
import * as i8 from "@angular/common/http";
import * as i9 from "../../elements/button-link/button-link.component.ngfactory";
import * as i10 from "../../elements/button-link/button-link.component";
import * as i11 from "@angular/router";
import * as i12 from "../../elements/button/button.component.ngfactory";
import * as i13 from "../../elements/button/button.component";
import * as i14 from "@angular/common";
import * as i15 from "../../elements/spinner/spinner.component.ngfactory";
import * as i16 from "../../elements/spinner/spinner.component";
import * as i17 from "./download-page.component";
import * as i18 from "@ngxs/store";
import * as i19 from "@angular/platform-browser";
import * as i20 from "../../api/repositories/download-repository.service";
var styles_DownloadPageComponent = [i0.styles];
var RenderType_DownloadPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadPageComponent, data: {} });
export { RenderType_DownloadPageComponent as RenderType_DownloadPageComponent };
function View_DownloadPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "app-empty", [["class", "fullsize"]], null, null, null, i2.View_EmptyComponent_0, i2.RenderType_EmptyComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmptyComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 19, "app-stack-layout", [["class", "text-center"]], [[4, "flexDirection", null]], null, null, i4.View_StackLayoutComponent_0, i4.RenderType_StackLayoutComponent)), i1.ɵdid(3, 49152, null, 0, i5.StackLayoutComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "app-icon", [["class", "empty-icon"], ["icon", "sad"], ["size", "120px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(5, 638976, null, 0, i7.IconComponent, [i1.ElementRef, i8.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h2", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No licenses found"])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "p", [["class", "mt-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You cannot download older version of Dataedo."])), (_l()(), i1.ɵeld(10, 0, null, 0, 11, "div", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "app-button-link", [["class", "mr-md"], ["href", "https://dataedo.com/download"], ["icon", "download"]], null, null, null, i9.View_ButtonLinkComponent_0, i9.RenderType_ButtonLinkComponent)), i1.ɵdid(12, 49152, null, 0, i10.ButtonLinkComponent, [i11.Router], { href: [0, "href"], openInNewTab: [1, "openInNewTab"], icon: [2, "icon"] }, null), (_l()(), i1.ɵted(-1, 0, ["Latest version"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "app-button", [["class", "mr-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ButtonComponent_0, i12.RenderType_ButtonComponent)), i1.ɵdid(15, 49152, null, 0, i13.ButtonComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Contact support "])), (_l()(), i1.ɵeld(17, 0, null, null, 4, "app-button-link", [], null, null, null, i9.View_ButtonLinkComponent_0, i9.RenderType_ButtonLinkComponent)), i1.ɵdid(18, 49152, null, 0, i10.ButtonLinkComponent, [i11.Router], { href: [0, "href"], openInNewTab: [1, "openInNewTab"] }, null), (_l()(), i1.ɵted(-1, 0, [" Get free trial "])), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "app-icon", [["class", "ml-xs"], ["icon", "external-link"], ["size", "16px"]], [[4, "width", null], [4, "height", null], [4, "display", null], [4, "verticalAlign", null]], null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i1.ɵdid(21, 638976, null, 0, i7.IconComponent, [i1.ElementRef, i8.HttpClient], { icon: [0, "icon"], size: [1, "size"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "sad"; var currVal_6 = "120px"; _ck(_v, 5, 0, currVal_5, currVal_6); var currVal_7 = "https://dataedo.com/download"; var currVal_8 = true; var currVal_9 = "download"; _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.freeTrialUrl; var currVal_11 = true; _ck(_v, 18, 0, currVal_10, currVal_11); var currVal_16 = "external-link"; var currVal_17 = "16px"; _ck(_v, 21, 0, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).direction; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 5).size; var currVal_2 = i1.ɵnov(_v, 5).size; var currVal_3 = i1.ɵnov(_v, 5).display; var currVal_4 = i1.ɵnov(_v, 5).verticalAlign; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_12 = i1.ɵnov(_v, 21).size; var currVal_13 = i1.ɵnov(_v, 21).size; var currVal_14 = i1.ɵnov(_v, 21).display; var currVal_15 = i1.ɵnov(_v, 21).verticalAlign; _ck(_v, 20, 0, currVal_12, currVal_13, currVal_14, currVal_15); }); }
function View_DownloadPageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["download", "true"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.link32, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DownloadPageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["download", "true"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.link64, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DownloadPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ".", ".", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_6)), i1.ɵdid(11, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_7)), i1.ɵdid(14, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_6 = _v.parent.context.$implicit.link32; _ck(_v, 11, 0, currVal_6); var currVal_7 = _v.parent.context.$implicit.link64; _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.version.major; var currVal_2 = _v.parent.context.$implicit.version.minor; var currVal_3 = _v.parent.context.$implicit.version.build; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.type; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.size; _ck(_v, 8, 0, currVal_5); }); }
function View_DownloadPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_5)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.version.major === _co.downloadLastVersion.major); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DownloadPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "table", [["style", "margin-left:20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Product"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Version"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Size"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download x32"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download x64"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_4)), i1.ɵdid(18, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.download; _ck(_v, 18, 0, currVal_0); }, null); }
function View_DownloadPageComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["download", "true"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.link32, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_DownloadPageComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ".", ".", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_11)), i1.ɵdid(11, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["download", "true"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], function (_ck, _v) { var currVal_6 = _v.parent.context.$implicit.link32; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.version.major; var currVal_2 = _v.parent.context.$implicit.version.minor; var currVal_3 = _v.parent.context.$implicit.version.build; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _v.parent.context.$implicit.type; _ck(_v, 6, 0, currVal_4); var currVal_5 = _v.parent.context.$implicit.size; _ck(_v, 8, 0, currVal_5); var currVal_7 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.link64, ""); _ck(_v, 13, 0, currVal_7); }); }
function View_DownloadPageComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_10)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.version.major !== _co.downloadLastVersion.major); _ck(_v, 2, 0, currVal_0); }, null); }
function View_DownloadPageComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "table", [["style", "margin-left:20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Product"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Version"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Type"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Size"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download x32"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download x64"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_9)), i1.ɵdid(18, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.download; _ck(_v, 18, 0, currVal_0); }, null); }
function View_DownloadPageComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner", [], [[2, "small", null]], null, null, i15.View_SpinnerComponent_0, i15.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i16.SpinnerComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fitToSize; _ck(_v, 0, 0, currVal_0); }); }
function View_DownloadPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "mt-lg ml-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Downloads"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "mt-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "mt-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Recent version:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_3)), i1.ɵdid(7, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h3", [["class", "mt-lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Older versions:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadPageComponent_8)), i1.ɵdid(11, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_DownloadPageComponent_12)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "mt-md"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "app-button", [["class", "mr-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ButtonComponent_0, i12.RenderType_ButtonComponent)), i1.ɵdid(15, 49152, null, 0, i13.ButtonComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, [" Contact support "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.download; var currVal_1 = i1.ɵnov(_v, 12); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = _co.download; var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_DownloadPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "app-stack-layout", [], [[4, "flexDirection", null]], null, null, i4.View_StackLayoutComponent_0, i4.RenderType_StackLayoutComponent)), i1.ɵdid(1, 49152, null, 0, i5.StackLayoutComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DownloadPageComponent_1)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DownloadPageComponent_2)), i1.ɵdid(5, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "div", [["class", "pt-md"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.isLoading && !_co.hasAnyLicenses); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.hasAnyLicenses; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).direction; _ck(_v, 0, 0, currVal_0); }); }
export function View_DownloadPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-download-page", [], null, null, null, View_DownloadPageComponent_0, RenderType_DownloadPageComponent)), i1.ɵdid(1, 245760, null, 0, i17.DownloadPageComponent, [i18.Store, i18.Actions, i19.Title, i20.DownloadRepositoryService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadPageComponentNgFactory = i1.ɵccf("app-download-page", i17.DownloadPageComponent, View_DownloadPageComponent_Host_0, {}, {}, []);
export { DownloadPageComponentNgFactory as DownloadPageComponentNgFactory };
