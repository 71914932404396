import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ofActionCompleted, Select } from '@ngxs/store';
import { MenuState } from '../../states/menu.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchMenu, PushMenuActiveAccountId } from '../../states/menu.actions';
import IntercomHelper from '../../helpers/intercom-helper';
import { FetchDownload } from '../../states/download.actions';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import { environment } from '../../../environments/environment';
export class DownloadPageComponent {
    constructor(store, actions, title, downloadRepository) {
        this.store = store;
        this.actions = actions;
        this.title = title;
        this.downloadRepository = downloadRepository;
        this.freeTrialUrl = `${environment.websiteUrl}free-trial`;
        this.loading = true;
        this.menu = null;
        this.downloadLastVersion = null;
        this.isLoading = true;
        this.gc = new Subject();
        // Token służący do anulowania pobierania danych.
        this.cancellationToken = new Subject();
    }
    get shouldShowPersonalLicenses() {
        if (!this.menu) {
            return false;
        }
        return this.menu.personal.trials || this.menu.personal.educationalLicenses;
    }
    get hasAnyLicenses() {
        if (!this.menu) {
            return false;
        }
        return this.shouldShowPersonalLicenses
            || this.menu.accounts.length > 0;
    }
    openChat() {
        IntercomHelper.show();
    }
    ngOnInit() {
        this.isLoading = true;
        this.title.setTitle('Your download - Dataedo Account');
        this.initEvents();
        this.fetchData();
        this.downloadRepository.list().pipe(takeUntil(this.gc))
            .subscribe(data => {
            this.downloadLastVersion = data.data.lastVersion;
            this.download = data.data.versions;
            this.isLoading = false;
        });
    }
    initEvents() {
        this.menuListener
            .pipe(takeUntil(this.gc))
            .subscribe(menu => this.menu = menu);
        this.actions
            .pipe(ofActionCompleted(FetchDownload))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchDownload(event));
        this.actions
            .pipe(ofActionCompleted(FetchMenu))
            .pipe(takeUntil(this.gc))
            .subscribe();
    }
    fetchData() {
        this.isLoading = true;
        this.store.dispatch(new PushMenuActiveAccountId(undefined));
        this.store.dispatch(new FetchDownload(this.cancellationToken));
        this.store.dispatch(new FetchMenu(this.gc));
    }
    onCompletedFetchDownload(event) {
        const response = ResponseHandlingHelper.parse(event.result);
        this.isLoading = false;
    }
    ngOnDestroy() {
        this.gc.next();
        this.gc.complete();
    }
}
tslib_1.__decorate([
    Select(MenuState.menu)
], DownloadPageComponent.prototype, "menuListener", void 0);
