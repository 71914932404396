import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActionCompletion, Actions, ofActionCompleted, Select, Store} from '@ngxs/store';
import {DownloadOfflineTrialLicense, FetchTrials} from '../../states/trials.actions';
import {takeUntil} from 'rxjs/operators';
import ResponseHandlingHelper from '../../helpers/response-handling-helper';
import {TrialsState} from '../../states/trials.state';
import {environment} from '../../../environments/environment';
import {TrialsModelResponse} from '../../api/responses/trials-response';
import {PushMenuActiveAccountId} from 'src/app/states/menu.actions';
import {Title} from '@angular/platform-browser';
import {DownloadOfflineLicense} from '../../states/accounts.actions';

@Component({
    selector: 'app-trials-page',
    templateUrl: './trials-page.component.html',
    styleUrls: ['./trials-page.component.scss']
})
export class TrialsPageComponent implements OnInit, OnDestroy {

    public freeTrialUrl = `${environment.websiteUrl}free-trial`;
    public changePackageUrl = `${environment.websiteUrl}upgrade-trial`;

    @Select(TrialsState.trials)
    public trialsListener: Observable<TrialsModelResponse[]>;
    public trials: TrialsModelResponse[] = [];

    public isDownloadingTrialId: number|null = null;

    /**
     * Określa czy aktualnie trwa pobieranie danych.
     */
    public isLoading = false;

    private gc = new Subject();

    /**
     * Token służący do anulowania pobierania danych.
     */
    private cancellationToken = new Subject();

    constructor(
        private store: Store,
        private actions: Actions,
        private title: Title
    ) {
    }

    public ngOnInit() {
        this.title.setTitle('Trials - Dataedo Account');
        this.initEvents();
        this.fetchData();
    }

    private fetchData() {
        this.isLoading = true;

        this.store.dispatch(new PushMenuActiveAccountId(null));
        this.store.dispatch(new FetchTrials(this.cancellationToken));
    }

    private initEvents() {
        this.trialsListener
            .pipe(takeUntil(this.gc))
            .subscribe(trials => this.trials = trials);

        this.actions
            .pipe(ofActionCompleted(FetchTrials))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedFetchTrials(event));

        this.actions
            .pipe(ofActionCompleted(DownloadOfflineTrialLicense))
            .pipe(takeUntil(this.gc))
            .subscribe(event => this.onCompletedDownloadOfflineTrialLicense(event));
    }

    private onCompletedFetchTrials(event: ActionCompletion<FetchTrials, Error>) {
        const response = ResponseHandlingHelper.parse(event.result);

        this.isLoading = false;
    }

    public trackTrialById(index: number, trial: TrialsModelResponse): number {
        return trial.id;
    }

    public downloadOfflineLicense(trial: TrialsModelResponse) {
        this.isDownloadingTrialId = trial.id;
        this.store.dispatch(new DownloadOfflineTrialLicense(trial.id, this.gc));
    }

    private async onCompletedDownloadOfflineTrialLicense(event: ActionCompletion<DownloadOfflineTrialLicense, Error>) {
        this.isDownloadingTrialId = null;
    }

    public ngOnDestroy(): void {
        this.cancellationToken.next();
        this.cancellationToken.complete();

        this.gc.next();
        this.gc.complete();
    }
}
